<template>
  <div>
    <h2 v-html="$t('search_milk_quality_rating_title')"></h2>
    <!-- ≥ Grenzwert -->
    <v-select
      id="search_milk_quality_rating_limit_value"
      v-model="SearchMilkQualityRatingStore.Quality_ThresholdExeededLab"
      :items="thresholdExeeded"
      :item-title="this.$getLangKey()"
      item-value="name"
      :label="$t('search_milk_quality_rating_limit_value')"
      clearable
      @keyup.enter="search"
    />
    <!-- Amtlich: Anzahl -->
    <v-select
      id="search_milk_quality_rating_official"
      v-model="SearchMilkQualityRatingStore.Quality_Complaints"
      :items="officialNumber"
      item-title="text"
      item-value="value"
      :label="$t('search_milk_quality_rating_official')"
      clearable
      @keyup.enter="search"
    />
    <!-- Amtliche: Kriterium -->
    <v-select
      id="search_milk_quality_rating_official_criteria"
      v-model="SearchMilkQualityRatingStore.Quality_ComplaintsCriterion"
      :label="$t('search_milk_quality_rating_official_criteria')"
      :items="officialCriteria"
      item-title="text"
      item-value="value"
      clearable
      @keyup.enter="search"
    />
    <!-- Amtlich: Liefersperre -->
    <v-select
      id="search_milk_quality_rating_deliveries_cessation"
      v-model="SearchMilkQualityRatingStore.Quality_DeliveryStopLab"
      :items="deliveryStop"
      :item-title="this.$getLangKey()"
      item-value="name"
      :label="$t('search_milk_quality_rating_deliveries_cessation')"
      clearable
      @keyup.enter="search"
    />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { app } from '../../main'

import { useDeliveryStopStore } from '@/store/enums/DeliveryStopStore'
import { useThresholdExeededStore } from '@/store/enums/ThresholdExeededStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchMilkQualityRating',
  extends: baseSearchVue,
  data() {
    return {
      app: app,
      // limitValues: [
      //   { id: 1, value: 'NO', text: this.$t('search_milk_quality_rating_no') },
      //   { id: 2, value: 'YES', text: this.$t('search_milk_quality_rating_yes') },
      //   { id: 3, value: 'GERMS', text: this.$t('search_milk_quality_rating_germs') },
      //   { id: 4, value: 'CELLS', text: this.$t('search_milk_quality_rating_cells') },
      //   { id: 5, value: 'FREEZING_POINT', text: this.$t('search_milk_quality_rating_freezing_point') }
      // ],
      officialNumber: [
        { value: 0, text: '= 0' },
        { value: 1, text: '>= 1' },
        { value: 2, text: '>= 2' },
        { value: 3, text: '>= 3' },
        { value: 4, text: '>= 4' },
        { value: 5, text: '>= 5' }
      ],
      officialCriteria: [
        {
          id: 1,
          value: 'GERMS',
          text: this.$t('search_milk_quality_rating_germs')
        },
        {
          id: 2,
          value: 'CELLS',
          text: this.$t('search_milk_quality_rating_cells')
        },
        {
          id: 3,
          value: 'INHIBITOR',
          text: this.$t('search_milk_quality_rating_inhibitor')
        }
      ]
      // deliveryStop: [
      //   { id: 1, value: 'NO', text: this.$t('search_milk_quality_rating_no') },
      //   { id: 2, value: 'YES', text: this.$t('search_milk_quality_rating_yes') },
      //   { id: 3, value: 'GERMS', text: this.$t('search_milk_quality_rating_germs') },
      //   { id: 4, value: 'CELLS', text: this.$t('search_milk_quality_rating_cells') },
      //   { id: 3, value: 'INHIBITOR', text: this.$t('search_milk_quality_rating_inhibitor') },
      //   { id: 5, value: 'FOLLOW_UP', text: this.$t('search_milk_quality_rating_follow_up') }
      // ]
    }
  },
  computed: {
    deliveryStop() {
      return useDeliveryStopStore().deliveryStop
    },
    thresholdExeeded() {
      return useThresholdExeededStore().thresholdExeeded
    },
    SearchMilkQualityRatingStore() {
      return this.$store.state.SearchMilkQualityRatingStore
    }
  },
  methods: {
    reset() {
      this.SearchMilkQualityRatingStore.Quality_ComplaintsCriterion = null
      this.SearchMilkQualityRatingStore.Quality_DeliveryStopLab = null
      this.resetFields(this.SearchMilkQualityRatingStore)
    }
  },
  mounted() {
    useDeliveryStopStore().fetchDeliveryStop()
    useThresholdExeededStore().fetchThresholdExeeded()
    this.initSearchStoreFromQuery(this.SearchMilkQualityRatingStore, this.$route.query)
  }
})
</script>
